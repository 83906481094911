import { Report } from "powerbi-client";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/all";
import { useCallback, useEffect, useState } from "react";
import { Page } from "page";

interface PowerBiReportPagesProps {
  report?: Report | null;
  isReportReady: boolean;
}

export function PowerBiReportPages(props: PowerBiReportPagesProps) {
  const { report, isReportReady } = props;
  const [pages, setPages] = useState<Page[]>([]);
  const [currentPageName, setCurrentPageName] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!report || !isReportReady) {
      return;
    }
    report.getPages().then(setPages).catch(console.error);
    report
      .getActivePage()
      .then(page => setCurrentPageName(page.displayName))
      .catch(console.error);
  }, [isReportReady, report]);

  const changePage = useCallback(
    (page: Page) => {
      if (!report) {
        return;
      }
      report.setPage(page.name).catch(console.error);
      setCurrentPageName(page.displayName);
    },
    [report],
  );

  if (!report || !pages.length || !isReportReady) {
    return null;
  }

  return (
    <Box px={5} py={2}>
      <Button
        rightIcon={<BiChevronDown />}
        w='100%'
        colorScheme='blue'
        borderRadius={25}
        onClick={onOpen}
        bg='#ffb800'
      >
        {currentPageName || "..."}
      </Button>
      <Drawer isOpen={isOpen} placement='bottom' onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Páginas</DrawerHeader>
          <DrawerBody px={5}>
            <VStack mb={10}>
              {pages.map(page => (
                <Button
                  key={page.name}
                  w='100%'
                  bg='#0e3663'
                  color='white'
                  borderRadius={25}
                  onClick={() => changePage(page)}
                >
                  {page.displayName}
                </Button>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
