import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report } from "powerbi-client";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./analytics-route.css";
import { PowerBiReportPages } from "./PowerBiReportPages";

export function AnalyticsRoute() {
  let { id } = useParams();
  const [data, setData] = useState<ReportInfo | null>(null);
  const [hasFailed, setHasFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idUser, setIdUser] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const intervalId = useRef<ReturnType<typeof setInterval>>();
  const [report, setReport] = useState<Report | null>(null);
  const [isReportReady, setIsReportReady] = useState(false);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setIdUser(localStorage.getItem("id-user"));
      setAccessToken(localStorage.getItem("access-token"));
    }, 1000);
    return () => clearInterval(intervalId.current);
  }, [accessToken, idUser]);

  useEffect(() => {
    if (!idUser || !accessToken) {
      return;
    }
    clearInterval(intervalId.current);
  }, [idUser, accessToken]);

  useEffect(() => {
    if (!id || !accessToken) {
      return;
    }
    setIsLoading(true);
    getReportInfo(Number(id), accessToken)
      .then(setData)
      .catch(err => {
        console.error(err);
        setHasFailed(true);
      })
      .finally(() => setIsLoading(false));
  }, [accessToken, id]);

  const calculatedFilters = useMemo(() => {
    if (id === "19") {
      return [
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "team-leaders",
            column: "idUser",
          },
          operator: "In",
          values: [idUser],
          filterType: models.FilterType.Basic,
          requireSingleSelection: true,
        },
      ];
    }
    if (id === "20") {
      return [
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "Activadores",
            column: "idUser",
          },
          operator: "In",
          values: [idUser],
          filterType: models.FilterType.Basic,
          requireSingleSelection: true,
        },
      ];
    }

    if (id === "24") {
      return [
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "Team Leaders",
            column: "idUser",
          },
          operator: "In",
          values: [idUser],
          filterType: models.FilterType.Basic,
          requireSingleSelection: true,
        },
      ];
    }
    return data?.filters.map(f => ({
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: f.table,
        column: f.column,
      },
      operator: "In",
      values: [f.value],
      filterType: models.FilterType.Basic,
      requireSingleSelection: true,
    }));
  }, [data?.filters, id, idUser]);

  if (hasFailed) {
    return (
      <Box textAlign='center' mt={25} mx={10}>
        <Alert
          borderRadius={10}
          status='error'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertDescription maxWidth='sm' mt={10}>
            Ha ocurrido un error al cargar el reporte.
          </AlertDescription>
        </Alert>
      </Box>
    );
  }

  if (!id || isLoading || !data || !idUser || !accessToken) {
    return (
      <Box textAlign='center' mt={25}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box h='100vh' w='100vw' display='flex' flexDirection='column'>
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: data.powerBiId,
          embedUrl: data.embedUrl,
          accessToken: data.accessToken,
          tokenType: models.TokenType.Aad,
          filters: calculatedFilters,
          settings: {
            layoutType: models.LayoutType.MobilePortrait,
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
              pageNavigation: {
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        }}
        cssClassName='power-bi-embed'
        getEmbeddedComponent={embedObject => {
          setReport(embedObject as Report);
        }}
        eventHandlers={
          new Map([
            [
              "rendered",
              function () {
                setIsReportReady(true);
              },
            ],
          ])
        }
      />
      <PowerBiReportPages report={report} isReportReady={isReportReady} />
    </Box>
  );
}

async function getReportInfo(id: number, accessToken: string) {
  const { data: allReportsResponse } = await axios.get<ReportResponse[]>(
    "https://api.happify.com.gt/analytics/report",
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  const reportInfo = allReportsResponse.filter(
    report => report.idReport === id,
  )[0];

  const { data: reportResponse } = await axios.get<ReportViewResponse>(
    `https://api.happify.com.gt/analytics/report/view/${reportInfo.idReport}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );

  return { ...reportInfo, ...reportResponse } as ReportInfo;
}

interface ReportResponse {
  idReport: number;
  powerBiId: string;
  nameReport: string;
  description: string;
  powerBiUrl: "https://app.powerbi.com/groups/77db684e-d925-4bf2-a6f3-00edfc9e37ba/reports/1da83c04-b25a-40d6-b564-eb445dfe2a5c/ReportSection";
  roles: string[];
  createdAt: number;
  updatedAt: string | null;
  idOrganization: string;
  filters: Filter[];
  nameReportType: string;
  idReportType: number;
  deleted: boolean;
}

interface Filter {
  idFilter: string;
  table: string;
  column: string;
  value: string;
}

interface ReportViewResponse {
  idReport: number;
  accessToken: string;
  embedUrl: string;
}

type ReportInfo = ReportResponse & ReportViewResponse;
