import { createBrowserRouter } from "react-router-dom";
import { NotFoundRoute } from "./NotFoundRoute";
import { AnalyticsRoute } from "./AnalyticsRoute";

export const browserRouter = createBrowserRouter([
  {
    path: "/",
    element: <NotFoundRoute />,
  },
  {
    path: "/analytics/:id",
    element: <AnalyticsRoute />,
  },
]);
