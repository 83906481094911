import * as React from "react";
import { useEffect } from "react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import { browserRouter } from "./browser-router";

export function App() {
  useEffect(() => {
    // @ts-ignore
    if ("%NODE_ENV%" !== "development" || !window.DD_RUM) {
      const interval = setInterval(() => {
        const idUser = localStorage.getItem("id-user");
        if (!idUser) {
          return;
        }
        // @ts-ignore
        window.DD_RUM.setUser({
          id: idUser,
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={browserRouter} />
    </ChakraProvider>
  );
}
